let ready = function () {

  $(document).on('click', '.js--guide__tabs-link', function (e) {
    e.preventDefault();
    $('.guide__tabs-link').removeClass('guide__tabs-link--active');
    $(this).addClass('guide__tabs-link--active');

    $('.guide__tab').removeClass('guide__tab--active');
    $($(this).attr('data-tab')).addClass('guide__tab--active');

    $('ul.nav.nav--active').removeClass('nav--active');
    $($('.guide__tab--active').attr('data-bs-target')).addClass('nav--active');

    let dataSpyList = [].slice.call(document.querySelectorAll('[data-bs-spy="scroll"]'));
    dataSpyList.forEach(function (dataSpyEl) {
      let spy = bootstrap.ScrollSpy.getInstance(dataSpyEl);
      if (spy) {
        bootstrap.ScrollSpy.getInstance(dataSpyEl).refresh();
      }
    });
  });
  $('.guide .nav .nav-link').hover(function() {
    $('.guide .nav .nav-link').addClass('hover');
  }, function() {
    $('.guide .nav .nav-link').removeClass('hover');
  })

  $(document).on('click', '.guide__block-menu-link', function (e){
    e.preventDefault();

    let $t = $(this), $active = $t.parent().find('.guide__block-menu-link--active');

    $($active.attr('href')).removeClass('active');

    $active.removeClass('guide__block-menu-link--active');
    $t.addClass('guide__block-menu-link--active');

    $($t.attr('href')).addClass('active');
  });

  $(document).on('click', '.control__icon--play', function (e) {
    e.preventDefault();
    let video = document.getElementById("video");
    let $butPlay = $('.control__icon--play');
    let $butPause = $('.control__icon--pause');

    $butPlay.removeClass('control__icon--active');
    $butPause.addClass('control__icon--active');
    video.play();
  })
  $(document).on('click', '.control__icon--pause', function (e) {
    e.preventDefault();
    let video = document.getElementById("video");
    let $butPlay = $('.control__icon--play');
    let $butPause = $('.control__icon--pause');

    $butPause.removeClass('control__icon--active');
    $butPlay.addClass('control__icon--active');
    video.pause();
  })
  $(document).on('click', '.control__icon--volume-on', function (e) {
    e.preventDefault();
    let video = document.getElementById("video");
    let $butVolumeOn = $('.control__icon--volume-on');
    let $butVolumeOff = $('.control__icon--volume-off');

    $butVolumeOn.removeClass('control__icon--active');
    $butVolumeOff.addClass('control__icon--active');
    video.muted = true;
  });
  $(document).on('click', '.control__icon--volume-off', function (e) {
    e.preventDefault();
    let video = document.getElementById("video");
    let $butVolumeOn = $('.control__icon--volume-on');
    let $butVolumeOff = $('.control__icon--volume-off');

    $butVolumeOff.removeClass('control__icon--active');
    $butVolumeOn.addClass('control__icon--active');
    video.muted = false;
  });


  $(document)
    .on('click', '.js--burger', function (e) {
      e.preventDefault();
      $('body').toggleClass('body-burger-show');
    })
    .on('click', '.js--header__link--channel', function (e) {
      // toggle channel KINOJAM1/KINOJAM2
      e.preventDefault();
      $(this).toggleClass('header__link--show')
      $('.subhead').toggleClass('subhead--show');
    })
    .on('click', '.btn-close', (e) => {
      // close bootstrap modal on turbolinks go back
      $('body').removeClass('modal-open').css({overflow: 'initial', paddingRight: 'initial'});
      $('.modal.show').removeClass('show').css({display: 'none'});
      $('.modal-backdrop').remove();
    })
    .on('click', '.js--switch-table', function () {
      // switch to table view
      $('.programs__tl').addClass('d-none');
      $('.programs__items').removeClass('d-none');
      $('.switch__item--table').addClass('switch__item--active');
      $('.switch__item--list').removeClass('switch__item--active');
      return false;
    })
    .on('click', '.js--switch-list', function () {
      // switch to list view
      $('.programs__tl').removeClass('d-none');
      $('.programs__items').addClass('d-none');
      $('.switch__item--table').removeClass('switch__item--active');
      $('.switch__item--list').addClass('switch__item--active');

      // Today's program
      moveNowInEfir();

      return false;
    });

  // main__channels-links
  $(document).on('click', '.main__channels-link', function () {
    $('.main__channels-link').removeClass('main__channels-link--active');
    $(this).addClass('main__channels-link--active');
    $('.main__channels-logo img').addClass('main__channels-logo-hide');
    $($(this).attr('data-logo')).removeClass('main__channels-logo-hide');

    $('.main__channels-desc').removeClass('main__channels-desc--active');
    $($(this).attr('data-tab')).addClass('main__channels-desc--active');
    return false;
  });

  // scroll up / down
  let lastScrollTop = 0;
  $(window).scroll(function (event) {
    let st = $(this).scrollTop();
    if (st > 20) {
      if (st > lastScrollTop) {
        $('body').addClass('scroll__down').removeClass('scroll__up');
      } else {
        $('body').addClass('scroll__up').removeClass('scroll__down');
      }
    } else {
      $('body').addClass('scroll__up').removeClass('scroll__down');
    }
    lastScrollTop = st;
  });
}, moveNowInEfir = function () {
  let $pr = $('.programs__tl-items'), $now = $('.programs__tl-now');
  if ($pr.length && $now.length) {
    setTimeout(function () {
      $pr.css({scrollBehavior: 'smooth'}).scrollLeft(parseInt($now.css('left'), 10));
    }, 200);
  }
};

$(document).ready(ready);
$(document).on('page:load', ready);

document.addEventListener('turbolinks:load', () => {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });


  // Carousel
  let $sl = $('.js--carousel-1');
  if ($sl.length) {
    $sl.on('init', function (event, slick) {
      $('body').addClass('slick-ready');
    });
    $sl.slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      arrows: false,
    });
  }
  // Carousel about
  let $sl2 = $('.js--carousel-about');
  if ($sl2.length) {
    $sl2.on('init', function (event, slick) {
      $('body').addClass('slick-ready');
    });
    $sl2.slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: false,
      arrows: false,
    });
  }

  // Carousel awards
  let $sl3 = $('.js--carousel-awards');
  if ($sl3.length) {
    $sl3.on('init', function (event, slick) {
      $('body').addClass('slick-ready');
    });
    $sl3.slick({
      dots: true,
      speed: 300,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      centerMode: false,
      variableWidth: false,
      arrows: false,
    });
  }


  // Today's program
  moveNowInEfir();
});

document.addEventListener("turbolinks:before-cache", function () {
  const sliders = document.querySelectorAll('.slick-initialized');

  sliders.forEach(item => {
    $(item).slick('unslick');
  });

  const videos = document.querySelectorAll('video');
  videos.forEach(video => {
    video.muted = true;
    video.pause();
  });
});

document.addEventListener("turbolinks:request-end", function () {
  if ($('video').length > 0) {
    $('video').remove();
  }
});
